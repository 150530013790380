import React, { useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import { history } from '../helpers'
// import { PrivateRoute } from '../components/PrivateRoute'
// import { HomePage } from './HomePage'
// import { DevicePage } from './DevicePage'
// import { LoginPage } from './LoginPage'
import { SignUpPage } from './SignUpPage'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import { ChangePasswordPage } from './ChangePasswordPage'
import { NotFoundPage } from './NotFoundPage'
import { OpenFromMobile } from './OpenFromMobile'
// import { makeStyles } from '@material-ui/core';
import Notifier from './Notifier'
// import { TokenAuth } from '../components/TokenAuth'
// import { SystemPage } from './SystemPage/SystemPage'
// import { ProblemPage } from './ProblemPage'
// import { SchedulePage } from './SchedulePage/SchedulePage'
// import { SnoozePage } from './SnoozePage'
// import { GuidePage } from './GuidePage'
// import { TechnicalDetailsPage } from './TechnicalDetailsPage'
// import { notificationService } from '../services/notification.service'
import { MagicRegisterPage } from './MagicRegisterPage'
import { AccessRequestPage } from './AccessRequestPage'
import { Unsubscribe } from './Unsubscribe'
import { HouseMigrationPage } from './HouseMigrationPage'
import { PaymentFailedPage } from './PaymentFailedPage'
import { PaymentSuccessPage } from './PaymentSuccessPage'
// import { SensorLogPage } from './SensorLogPage'

// const useStyles = makeStyles(theme => ({
//   drawerHeader: {
//     ...theme.mixins.toolbar,
//   },
// }));

export const App = (props) => {

  useEffect(function () {
    // notificationService.init()
  }, [])

  // const { auth } = props
  // const classes = useStyles();

  return (
    <Router history={history}>
      {/*<TokenAuth/>*/}
      {/*<div className={classes.drawerHeader} />*/}
      <Notifier />
      <Switch>
        <Route path="/registration" component={MagicRegisterPage} />
        {/*<Route path="/login" component={LoginPage}/>*/}
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/forgotPassword" render={({ location }) => (
          <Redirect to={{ ...location, pathname: '/change-password' }} />
        )} />
        <Route path="/change-password" component={ChangePasswordPage} />
        <Route path="/sign-up" component={SignUpPage}/>
        {/*<PrivateRoute exact path="/home" component={HomePage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory" component={SystemPage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id" component={DevicePage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/problem"*/}
        {/*              component={ProblemPage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/schedule"*/}
        {/*              component={SchedulePage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/snooze" component={SnoozePage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/guide" component={GuidePage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/technical-details"*/}
        {/*              component={TechnicalDetailsPage}/>*/}
        {/*<PrivateRoute exact path="/house/:houseId/system/:systemCategory/appliance/:id/sensor-log"*/}
        {/*              component={SensorLogPage}/>*/}
        {/*<Route path="/" render={() => (*/}
        {/*  auth.loggedIn ? (*/}
        {/*    <Redirect to="/home"/>*/}
        {/*  ) : (*/}
        {/*    <Redirect to="/login"/>*/}
        {/*  )*/}
        {/*)}/>*/}
        <Route path="/mobile" component={OpenFromMobile} />
        <Route path="/dealeraccessrequests" component={AccessRequestPage} />
        <Route path="/housemigrationrequests" component={HouseMigrationPage} />
        <Route path="/payment-failed" component={PaymentFailedPage} />
        <Route path="/payment-success" component={PaymentSuccessPage} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

function mapState (state) {
  const { auth } = state
  return { auth }
}

const actionCreators = {}

export default connect(mapState, actionCreators)(App)
